import React, { useEffect, useState } from "react"
import { changeDateMap, changeMapColors, findLastEqualElement, getDadosfato, getRequest } from "../../index"
import { get1, get10, get11, get12, get2, get3, get4, get5, get6, get7, get8, get9, getData, getMenuToggle, getModeMap, set1, set10, set11, set12, set2, set3, set4, set5, set6, set7, set8, set9, setDateFile, setMenuToggle, setSlideDate } from "../../DataHandler/DataHandler.jsx"
import { Loading } from "../../Loading/LoadingScreen.jsx"
import { Calendario } from "../Calendario/Calendario.jsx"

var d
var dataTimeControl;
var dataTimeControlIA;
var dataTimeShow;
var mes = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

function returnDate(number) {
    d = new Date();
    d.setMonth(d.getMonth() - number);
    let numericMonth = d.getMonth() + 2
    if (numericMonth >= 0 && numericMonth < 10) { numericMonth = "0" + numericMonth }
    const year = d.getFullYear()
    dataTimeControlIA = year + "-" + numericMonth + "-01"
}

export function startTimeControl() {
    returnDate(1)
    changeDateMap(dataTimeControlIA)
}

function setNumbers(number) {
    d = new Date();
    d.setMonth(d.getMonth() - number);
    const month = mes[d.getMonth()]
    const year = d.getFullYear()
    dataTimeShow = month + "/" + year
    return dataTimeShow
}

for (let i = 12; i > 0; i--) {
    if (i === 12) { set12(setNumbers(i)) }
    else if (i === 11) { set11(setNumbers(i)) }
    else if (i === 10) { set10(setNumbers(i)) }
    else if (i === 9) { set9(setNumbers(i)) }
    else if (i === 8) { set8(setNumbers(i)) }
    else if (i === 7) { set7(setNumbers(i)) }
    else if (i === 6) { set6(setNumbers(i)) }
    else if (i === 5) { set5(setNumbers(i)) }
    else if (i === 4) { set4(setNumbers(i)) }
    else if (i === 3) { set3(setNumbers(i)) }
    else if (i === 2) { set2(setNumbers(i)) }
    else if (i === 1) { set1(setNumbers(i)) }
}

export default function PrepareTimeControl({ predefinicoes }) {
    const [data, setTimeData] = useState()
    const [step, setStep] = useState("12")
    // eslint-disable-next-line no-unused-vars
    // const start = useState(() => teste())

    useEffect(() => {
        (async () => {
            let listaIa = predefinicoes.apilistaia;
            let listaFato = predefinicoes.apilistafato;
            if (listaFato && listaIa) {
                listaFato = await getRequest(listaFato);
                listaIa = await getRequest(listaIa);

                const monthEqual = findLastEqualElement(listaIa, listaFato);
                const difference = monthDifference(monthEqual)
                const [year, month] = monthEqual.split('-');
                const formattedMonth = mes[parseInt(String(parseInt(month) - 1), 10)];
                setStep(`${12 - (difference)}`)
                setTimeData(formattedMonth + "/" + year)
            } else {
                returnDate(1)
            }
        })();
    }, []);

    function monthDifference(dateStr) {
        const [year, month, day] = dateStr.split("-").map(item => parseInt(item, 10));
        const givenDate = new Date(year, month - 1, day);
        const currentDate = new Date();
        let monthDiff = (currentDate.getFullYear() - givenDate.getFullYear()) * 12 + currentDate.getMonth() - givenDate.getMonth();
        if (currentDate.getDate() < givenDate.getDate()) {
            monthDiff -= 1;
        }
        return Math.floor(monthDiff);
    }

    useEffect(() => {
        const rangeID = document.getElementById("range")
        if (getData() !== undefined) { setTimeData(getData()); setDateFile(getData()) }
        if (getData() === get1()) { rangeID.value = 12 }
        else if (getData() === get2()) { rangeID.value = 11 }
        else if (getData() === get3()) { rangeID.value = 10 }
        else if (getData() === get4()) { rangeID.value = 9 }
        else if (getData() === get5()) { rangeID.value = 8 }
        else if (getData() === get6()) { rangeID.value = 7 }
        else if (getData() === get7()) { rangeID.value = 6 }
        else if (getData() === get8()) { rangeID.value = 5 }
        else if (getData() === get9()) { rangeID.value = 4 }
        else if (getData() === get10()) { rangeID.value = 3 }
        else if (getData() === get11()) { rangeID.value = 2 }
        else if (getData() === get12()) { rangeID.value = 1 }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData()])

    function returnDate(number) {
        d = new Date();
        d.setMonth(d.getMonth() - number);
        const month = mes[d.getMonth() + 1]
        let numericMonth = d.getMonth() + 2
        if (numericMonth >= 0 && numericMonth < 10) { numericMonth = "0" + numericMonth }
        const year = d.getFullYear()
        dataTimeControl = year + "-" + numericMonth + "-01"
        dataTimeControlIA = year + "-" + numericMonth + "-01"
        dataTimeShow = month + "/" + year
        setTimeData(dataTimeShow)
        setDateFile(dataTimeShow)
        setSlideDate(d)
        return dataTimeShow
    }

    const timeControlChange = (ev) => {
        if (ev.target.value === "1") { returnDate(12) }
        else if (ev.target.value === "2") { returnDate(11) }
        else if (ev.target.value === "3") { returnDate(10) }
        else if (ev.target.value === "4") { returnDate(9) }
        else if (ev.target.value === "5") { returnDate(8) }
        else if (ev.target.value === '6') { returnDate(7) }
        else if (ev.target.value === '7') { returnDate(6) }
        else if (ev.target.value === '8') { returnDate(5) }
        else if (ev.target.value === '9') { returnDate(4) }
        else if (ev.target.value === '10') { returnDate(3) }
        else if (ev.target.value === '11') { returnDate(2) }
        else if (ev.target.value === '12') { returnDate(1) }
    }

    const changeMapDate = () => {
        Loading("iniciar")
        if (getModeMap() === "rugosidade ia") {
            getRequest(`${getDadosfato()}${dataTimeControl}.json`).then((response) => {
                changeMapColors(response)
            })
            changeDateMap(dataTimeControlIA)
        } else if (getModeMap() === "ia") {
            changeDateMap(dataTimeControlIA)
        } else if (getModeMap() === "rugosidade") {
            getRequest(`${getDadosfato()}${dataTimeControl}.json`).then((response) => {
                changeMapColors(response)
            })
        }
    }

    const openCalendar = () => {
        setMenuToggle(!getMenuToggle())
        document.getElementById("calendarModule").classList.toggle("active")
        if (getMenuToggle() === false) {
            setTimeout(() => { document.querySelectorAll(".toggleSessionsEnable i").forEach(element => { element.style.opacity = '0' }); document.getElementById("extensionFilter").style.display = "none" }, 150);
            setTimeout(() => { document.querySelectorAll(".sliderButton").forEach(element => { element.style.opacity = '0' }) }, 150);
        } else {
            setTimeout(() => { document.querySelectorAll(".toggleSessionsEnable i").forEach(element => { element.style.opacity = '1' }); document.getElementById("extensionFilter").style.display = "flex" }, 150);
            setTimeout(() => { document.querySelectorAll(".sliderButton").forEach(element => { element.style.opacity = '1' }) }, 150);
        }
        document.getElementById("TimeControl").classList.toggle("active")
    }

    return (
        <>
            <Calendario />
            <div id="TimeControl" className="TimeControl">
                <div className="calendarButton">
                    <button id="restoreButtonID" onClick={openCalendar} className="restoreButton"><i className="fal fa-calendar-alt"></i></button>
                </div>
                <label htmlFor="range" className="slideCenter" >
                    <div className="centerElement">
                        <output htmlFor="range" id="output" className="output" style={{ fontSize: "13px" }}> {data} </output>
                    </div>
                    <div className="centerElement">
                        <input className="slider"
                            onChange={(ev) => { timeControlChange(ev); setStep(ev.target.value) }} onMouseUp={changeMapDate}
                            type="range" name="range" id="range" min="1" max="12" step="1" value={step}
                        />
                    </div>
                    <div></div>
                </label>
            </div>
        </>
    )
}
